import { include } from 'named-urls';
import { OnboardingStatus } from 'domains/client';
import { LDFlagSet } from 'launchdarkly-react-client-sdk';

export const routes = {
  root: '/',
  userError: '/error',
  resetPassword: '/resetPassword',
  invitation: '/invitation',
  selectProfile: '/select-profile',
  selectState: '/select-state',
  signupV1: '/signup',
  signupV2: '/v2/signup',
  licenses: '/licenses',
  screener: include('/screener', {
    base: '',
    results: include('results', { base: '' }),
    signup: 'signup',
  }),
  pdf: include('pdf', {
    screener: 'screener',
    carePlan: 'care-plan',
    superbill: 'superbill',
  }),
  intake: include('/intake', {
    adult: 'adult',
    child: 'child',
    completed: 'completed',
  }),
  screening: include('/screening', {
    new: 'new',
    confirm: 'confirm',
    intake: 'intake',
  }),
  evaluation: include('/evaluation', {
    new: 'new',
    confirm: 'confirm',
    intake: 'intake',
  }),
  evaluationDirectAdult: include('/evaluation/adult', {
    new: 'new',
    confirm: 'confirm',
    intake: 'intake',
  }),
  evaluationDirectChild: include('/evaluation/child', {
    new: 'new',
    confirm: 'confirm',
    intake: 'intake',
  }),
  carePlan: include('/care-plan', {
    evaluationReport: 'evaluation-report/:reportId',
  }),
  session: include('/session', {
    detail: ':sessionId',
  }),
  careHistory: '/care-history',
  resourceLibrary: '/resource-library',
  billing: '/billing',
  billingUpdatePaymentMethod: '/billing/update-payment-method',
  claims: '/billing/claims/:clientId/:claimId',
  rescheduleDirect: '/reschedule/:clientId/:rescheduleId',
  scheduleDirect: '/schedule/:clientId/:scheduleId',
  rescheduleAppointment: '/reschedule-appointment/:appointmentId',
  cancelAppointment: '/cancel-appointment/:appointmentId',
  changeAppointment: '/change-appointment/:appointmentId',
  confirmReschedule: '/reschedule/:clientId/:rescheduleId/confirm',
  confirmSchedule: '/schedule/:clientId/:scheduleId/confirm',
  assignments: include('/assignments', {
    detail: ':assignmentId',
    completed: ':assignmentId/completed',

    previews: include('preview', {
      learningPath: 'learningPath/:learningPathId',
      ADL: 'adl/:learningPathId/:adlId',
      structuredActivity: 'structuredActivity/:structuredActivityId',
      customStructuredActivity: 'customStructuredActivity/:customStructuredActivityId',
    }),
  }),

  homePractice: '/home-practice',
  rescheduleExpired: '/reschedule/expired',

  support: include('/support', {
    base: '',
    category: ':categoryName',
    article: ':categoryName/:articleName',
  }),

  releaseOfInformation: '/release-of-information',
  fileUploadForm: '/file-upload-form',
} as const;

export const getNavBarRoutesForUser = (userStatus: OnboardingStatus, flags: LDFlagSet) => {
  if (userStatus === 'ongoing_sessions') {
    const userRoutes: { route: string; label: string }[] = [
      {
        route: routes.root,
        label: 'navigationBar.carePlanText',
      },
      {
        route: routes.careHistory,
        label: 'navigationBar.careHistoryText',
      },
      {
        route: routes.resourceLibrary,
        label: 'navigationBar.resourceLibrary',
      },
      {
        route: routes.homePractice,
        label: 'navigationBar.homePractice',
      },
      {
        route: routes.support.base,
        label: 'navigationBar.support',
      },
    ];

    if (flags?.portalBillingEnabled) {
      userRoutes.push({
        route: routes.billing,
        label: 'navigationBar.billing',
      });
    }
    return userRoutes;
  }

  return [];
};

const DISALLOWED_REFERRERS: string[] = [routes.selectProfile, routes.selectState];

export const allowedReferrerRedirect = (route: string | undefined): string | undefined => {
  if (route && DISALLOWED_REFERRERS.includes(route)) {
    return undefined;
  }

  return route;
};
