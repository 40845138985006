/**
 * @FIXME(shawk):
 * Until an upstream dependency issue in "eslint-plugin-react" is resolved,
 * disabling eslint rule "react/prop-types" is the recommended approach
 * to resolve false positives in eslint.
 *
 * see:
 * - https://github.com/yannickcr/eslint-plugin-react/issues/3140
 * - https://github.com/redwoodjs/redwood/pull/3762
 */
 
import cx from 'classnames';
import React from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { reverse, ReverseParams } from 'named-urls';
import { preload as preloadRoute, resolvedPathToPattern } from 'utils/routing';
import { Icon } from 'components/Icon';

export type Props = {
  to: string;
  className?: string;
  children?: React.ReactNode;
  preload?: boolean;
  params?: ReverseParams;
  disableExternalLinkIcon?: boolean;
  includeDefaultClasses?: boolean;
} & Omit<LinkProps, 'to'>;

// @NOTE(shawk): this won't work server-side, if we end up supporting SSR
const testAnchor = document.createElement('a');

const isExternalLink = (to: LinkProps['to']): boolean => {
  if (typeof to === 'string') {
    testAnchor.href = to;
    return testAnchor.host !== window.location.host;
  }

  return false;
};

export const Link = React.forwardRef<HTMLAnchorElement, Props>(function LinkWithRef(
  {
    children,
    className,
    to,
    preload = true,
    disableExternalLinkIcon = false,
    includeDefaultClasses = false,
    state,
    params,
    ...linkProps
  },
  ref,
): JSX.Element {
  const path = params ? reverse(to, params) : to;

  const onIntent = () => {
    preloadRoute(resolvedPathToPattern(path), params, state);
  };

  const linkClasses = cx(className, 'link', {
    'link-default': includeDefaultClasses,
  });

  if (isExternalLink(path)) {
    return (
      <a
        href={path as string}
        rel="nofollow noreferrer"
        target="_blank"
        ref={ref}
        className={linkClasses}
        {...linkProps}
      >
        {children}

        {!disableExternalLinkIcon && <Icon name="ExternalLink" className="inline-block ml-4 -mt-2" />}
      </a>
    );
  }

  return (
    <RouterLink
      {...linkProps}  
      ref={ref}
      to={path}
      state={state}
      className={linkClasses}
      onMouseEnter={preload ? onIntent : undefined}
      onFocus={preload ? onIntent : undefined}
    >
      {children}
    </RouterLink>
  );
});
