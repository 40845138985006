import cx from 'classnames';
import { twMerge } from 'tailwind-merge';
import React, { ComponentPropsWithoutRef } from 'react';

import { Link } from 'components/Link';
import { PolymorphicComponentProps } from 'types/component-types';

export type BaseProps = {
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  children?: React.ReactNode;
  iconOnly?: boolean;
  fullWidth?: boolean;
} & ComponentPropsWithoutRef<'button'>;

export type Props<C extends React.ElementType> = PolymorphicComponentProps<C, BaseProps>;

const defaultElement = 'button';

export const BaseButton = React.forwardRef(function BaseButtonWithRef<C extends React.ElementType = 'button'>(
  { as, type, size = 'md', className, children, iconOnly = false, fullWidth = false, ...props }: Props<C>,
  ref?: React.ForwardedRef<Element>,
) {
  const Component: React.ElementType = as || defaultElement;

  const buttonClasses = twMerge(
    cx(
      'inline-flex items-center justify-center rounded-full outline-none leading-none ring-offset-0 focus-visible:ring-offset-2 focus-visible:ring-offset-white focus-visible:ring-2 motion-reduce:transition-none',
      {
        'text-sm md:text-md min-h-[56px] py-20 px-32 md:leading-4 leading-[14px]': size === 'lg',
        'text-sm md:text-md md:min-h-[40px] min-h-[48px] py-12 px-24 md:leading-4 leading-[14px]': size === 'md',
        'text-sm min-h-[32px] py-[9px] px-16 md:leading-[14px] leading-[14px]': size === 'sm',
        'p-16': iconOnly && size === 'lg',
        'p-8': iconOnly && size === 'md',
        'p-6': iconOnly && size === 'sm',
        'w-full': fullWidth,
      },
      className,
    ),
  );

  const isLink = Component === 'a' || Component === Link;
  const isButton = Component === 'button';

  return (
    <Component
      {...props}  
      ref={ref}
      type={isButton ? type : undefined}
      onClick={props.onClick}
      className={buttonClasses}
    >
      {isLink ? children : <div className="grid">{children}</div>}
    </Component>
  );
});
